import Service from './Service';

class PermissionService extends Service {
    constructor() {
        super();
    }
    async getPermissionsCrudtbl() {
        return this.callApiGet(`/permissionsCrudtbl`);
    }
    async get(pageParam) {
        return this.callApiGet(`/permission${pageParam}`);
    }
    async create(postData) {
        return this.callApiPost(`/permission`, postData);
    }
    async update(postData) {
        return this.callApiPut(`/permission`, postData);
    }
    async delete(id) {
        return this.callApiDelete(`/permission?_ids=${id}`);
    }
}
export default PermissionService
